<template>
  <div class="container">
    <van-nav-bar
        title="个人信息编辑"
        left-arrow
        fixed
        @click-left="onClickLeft"
    />

    <div class="register-box">
      <van-form @submit="onSubmitRegister" class="register-form">
        <van-field
            v-model="registerForm.name"
            name="name"
            label="姓名"
            placeholder="请仔细填写并核对！"
            required
            :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
            readonly
            v-model="registerForm.sex_text"
            name="sex"
            required
            label="性别"
            placeholder="性别"
            :rules="[{ required: true, message: '请选择性别' }]"
            @click="showDate(1)"
        />
        <van-field
            v-model="registerForm.id_card"
            name="id_card"
            label="身份证号"
            placeholder="请仔细填写并核对！"
            required
            :rules="[{ required: true, message: '请填写身份证号' }]"
        />
        <!--        <van-field
                    v-model="registerForm.password"
                    type="password"
                    name="password"
                    required
                    label="密码"
                    placeholder="密码6-16位英文字母数字特殊字符"
                    :rules="[{ required: true, message: '请填写密码' }]"
                />
                <van-field
                    v-model="registerForm.check_password"
                    type="password"
                    name="password"
                    required
                    label="确认密码"
                    placeholder="再次输入密码确认"
                    :rules="[{ required: true, message: '再次输入密码确认' }]"
                    :error-message="check_password_msg"
                    @blur="checkPassword"
                />-->
        <van-field
            v-model="registerForm.phone"
            name="phone"
            required
            label="手机号码"
            placeholder="手机号码"
            :rules="[{ required: true, message: '请填写手机号码' }]"
        />
        <van-field
            readonly
            v-model="registerForm.education_text"
            name="education_text"
            label="学历"
            placeholder="学历"
            @click="showDate(4)"
        />
        <van-field
            readonly
            v-model="registerForm.major_text"
            name="major_text"
            label="专业"
            placeholder="专业"
            @click="showDate(5)"
        />

        <van-field
            readonly
            v-model="registerForm.register_text"
            name="date"
            required
            label="报名类型"
            placeholder="请选择报名类别"
            :rules="[{ required: true, message: '请选择报名类别' }]"
            @click="showDate(7)"
        />
        <van-field
            readonly
            v-model="registerForm.date"
            name="date"
            required
            label="资格获取时间"
            placeholder="请填写资格证书上的批准时间"
            :rules="[{ required: true, message: '请选择资格获取时间' }]"
            @click="showDate(0)"
        />
        <van-field
            readonly
            v-model="registerForm.qualification_text"
            name="qualification_text"
            required
            label="资格类别"
            placeholder="资格类别"
            :rules="[{ required: true, message: '请选择资格类别' }]"
            @click="showDate(2)"
        />
        <van-field
            v-model="registerForm.certificate_no"
            name="id_card"
            label="资格证号"
            placeholder="请填写资格编号或管理号！"
            required
            :rules="[{ required: true, message: '请填写资格编号或管理号' }]"
        />
        <van-field v-show="show_no2"
                   v-model="registerForm.certificate_no2"
                   name="certificate_no2"
                   label="资格证号2"
                   placeholder="仅双证人员填写！"
                   :required="registerForm.qualification_type === 3"
                   :rules="[{ required: registerForm.qualification_type === 3, message: '请填写资格编号或管理号' }]"
        />

        <van-field
            readonly
            name="area_name"
            required
            label="执业地区"
            placeholder="执业地区"
        >
          <template #input>
            <input :class="province_area ? 'area-input': 'province-input'" type="text" placeholder="请选择省份" :value="registerForm.province_name"  readonly @click="showDate(3)">
            <input v-show="province_area"  class="area-input" type="text" placeholder="请选择城市" :value="registerForm.area_name" readonly @click="showDate(8)">
          </template>
        </van-field>
        <van-field
            v-model="registerForm.company"
            name="company"
            label="执业单位"
            placeholder="暂无单位填写无！"
        />
        <van-field
            readonly
            v-model="registerForm.career_text"
            name="career_text"
            label="职称"
            placeholder="职称"
            @click="showDate(6)"
        />
        <van-field
            v-model="registerForm.code"
            name="code"
            required
            label="验证码"
            placeholder="验证码"
            :rules="[{ required: true, message: '请填写验证码' }]"
        >
          <template #right-icon>
            <img :src="reg_code_img_url" @click="onCLickCodeImage(1)" class="logo">
          </template>
        </van-field>
        <div style="position: fixed;bottom: 0;left: 0;right: 0;border-top: 0.1rem solid #e3e3e3;">
          <van-cell center>
            <div slot="title" style="margin: 1rem;text-align: center;">
              <van-button round type="info" block native-type="submit" class="submit-but">提交</van-button>
              <!-- <van-button round type="primary" native-type="button" @click="onLogin"  class="register-but">返回</van-button> -->
            </div>
          </van-cell>
        </div>
      </van-form>
      <van-overlay v-show="show_select_date">
        <div class="show-date">
          <van-datetime-picker
              v-model="currentDate"
              type="year-month"
              title="选择年月"
              :max-date="maxDate"
              :min-date="minDate"
              @confirm="confirmDate"
              @cancel="cancelDate"
          />
        </div>
      </van-overlay>
      <van-popup class="agreement-register-pop"
                 v-model="agreement_read_show"
                 closeable
                 close-icon="close"
                 position="bottom"
                 :style="{ height: '100%' }"
      >
        <div class="popper-title">
          继续教育学员服务条款-注册须知
        </div>
        <div class="popper-content">
          <p class='content-title'>
            <strong >1、继续教育服务条款的确认和接纳</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;继续教育的各项网络服务的所有权、运作权归继续教育。继续教育提供的服务将完全按照其发布的章程、服务条款和操作规则严格执行。您必须完全同意所有服务条款并完成注册程序。</span>
          </p>
          <p class='content-title'>
            <strong >2、服务简介</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;基于继续教育所提供的网络服务的重要性，学员应同意：</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;（1）提供详尽、准确的个人资料。</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;（2）不断更新注册资料,符合及时、详尽、准确的要求。继续教育不公开学员的姓名、地址、电子邮箱， 除以下情况外：</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;1)学员授权继续教育透露这些信息。</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;2)相应的法律及程序要求继续教育提供学员的个人资料。如果学员提供的资料包含有不正确的信息，继续教育保留结束学员使用网络服务资格的权利。</span>
          </p>
          <p class='content-title'>
            <strong >3、服务条款的修改和服务修订</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;继续教育有权在必要时修改服务条款，继续教育服务条款一旦发生变动，将会在重要页面上提示修改内容。如果学员继续享用网络服务，则视为接受服务条款的变动。</span>
          </p>
          <p class='content-title'>
            <strong >4、用户名、密码及使用限制</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;学员一旦注册成功，成为继续教育的合法学员，将得到用户名和密码，学员将对用户名、密码安全及用户名的使用负全部责任；</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;同时，每个学员的学习账号仅限学员个人私自使用，学员以任何方式与任何第三方共享学习账号或公开学习课程（包括但不限于向任何第三方透露学习课程、与他人共享学习账号、将自己的学习账号提供给第三方使用、将学习课程公开播放或以任何方式供多人同时使用）都是严格禁止的；</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;上述情况一旦发生，我司将立即停止违规账号的学习权限，同时我司会进一步追究违规人员的法律责任，包含不限于追偿损失、司法追责等。</span>
          </p>
          <p class='content-title'>
            <strong >5、对学员信息的存储和限制</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;继续教育不对学员所发布信息的删除或储存失败负责。继续教育有判定学员的行为是否符合继续教育服务条款的要求和精神的保留权利，如果学员违背了服务条款的规定，继续教育有中断对其提供网络服务的权利。</span>
          </p>
          <p class='content-title'>
            <strong >6、学员管理</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;严禁发表、散布、传播任何反动、色情及违反国家安全、扰乱社会秩序等有害信息，学员需对自己在网上的行为承担法律责任。学员若在继续教育上散布和传播反动、色情或其他违反国家法律的信息，继续教育的系统记录将作为学员违反法律的证据。</span>
          </p>
          <p class='content-title'>
            <strong >7、开始/结束服务</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;学员付费经确认后，开通相应的服务权限（服务权限是指学员享受所购买服务的资格）。具体服务内容开通的时间和进度以网站的最新公告或网站公告为准。</span>
          </p>
          <p class='content-title'>
            <strong >8、学员咨询</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;在如下时间里您可拨打网校的客服咨询电话：</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;咨询服务电话：13521319995</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;咨询时间：全天24小时服务（周六、周日及节假日不休息）</span>
          </p>
          <p class='content-title'>
            <strong >9、网络服务内容的所有权</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;继续教育定义的网络服务内容包括：文字、软件、声音、图片、录像、图表、邮件及广告中的全部内容，继续教育拥有以上内容的完全版权，严禁任何个人或单位在未经继续教育许可的情况下对这些内容进行翻版、复制、转载、篡改等一切用于商业活动的行为；继续教育的学员账号只为本网校的个人注册用户本人所专有，严禁一个账号多人使用，如若发现上述情况，继续教育将有权停止其账号使用，并没收其非法所得，并根据情节的严重程度对其实行相应罚款或述诸法律。</span>
          </p>
          <p class='content-title'>
            <strong >10、免责条款</strong>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;当本网站以链接形式推荐其他网站内容时，由于本站并不控制相关网站和资源，因此访问者需理解并同意，本站并不对这些网站或资源的可用性负责，且不保证从这些网站获取的任何内容、产品、服务或其他材料的真实性、合法性，对于任何因使用或信赖从此类网站或资源上获取的内容、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，本站均不承担任何责任。</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;由于用户将个人密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露，本网站不负任何责任。</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营的不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等，本网站均得免责。</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;本网站如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本公司控制范围外的硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本网站不负任何责任。</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;本网站使用者因为违反本声明的规定而触犯中华人民共和国法律的，一切后果自己负责，本网站不承担任何责任。</span>
          </p>
          <p >
            <span >&nbsp;&nbsp;&nbsp;本声明未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。</span>
          </p>
        </div>
      </van-popup>
    </div>
    <van-overlay v-show="show_select_picker">
      <div class="show-date">
        <van-area v-show="show_select_area"
                  :area-list="areaList"
                  @cancel="cancelPicker"
                  :columns-placeholder="['请选择', '请选择']"
                  :columns-num="2"
                  @confirm="areaConfirm" />
        <van-picker v-show="!show_select_area"
                    :title="picker_title"
                    show-toolbar
                    :columns="picker_columns"
                    @confirm="confirmPicker"
                    @cancel="cancelPicker"
        />
      </div>
    </van-overlay>
  </div>

</template>
<script>
const API_HOST = process.env.VUE_APP_BASE_API;
import {areaList} from '@vant/area-data';
import Vue from 'vue';
import {DatetimePicker, Overlay, Picker, Popup, NavBar} from 'vant'
import {editMemberInfo, MemberInfoDetail,getRegisterSelectData} from "@/request/api";

Vue.use(DatetimePicker)
Vue.use(Overlay)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(NavBar)
export default {
  data() {
    return {
      show_no2:true,
      show_select_date:false,
      show_select_picker:false,
      show_select_area:false,
      agreement:false,
      agreement_read_show:false,
      province_area:true,
      check_password_msg:'',
      picker_type:0,
      minDate: new Date(1970,1,1),
      maxDate: new Date(),
      currentDate:new Date(),
      picker_columns:[],
      areaList:{},
      reg_code_img_url:'',
      login_code_img_url:'',
      picker_title:'',
      education_list:[],
      cate_list:[],
      province_list:[],
      city_list:[],
      occupation_list:[],
      technical_title:[],
      branch_province_id:[],
      qualification_list:[],
      loginForm: {
        username: '',
        password: '',
        code: '',
        code_type: 1,
      },
      registerForm: {
        name: '',
        password: '',
        check_password:'',
        sex_text: '',
        sex:1,
        id_card:'',
        phone: '',
        date:'',
        qualification_text:'',
        qualification_type:'',
        certificate_no:'',
        certificate_no2:'',
        company:'',
        area_name:'',
        area_id:0,
        province_name:'',
        province_id:0,
        education_text:'',
        education_type:0,
        major_text:'',
        major_type:0,
        career_text:'',
        career_type:0,
        code_type:1,
        branch:2,
        register_type:0,
        register_text:'',
        __token__: Math.random(),
      }
    }
  },
  computed: {},
  mounted() {
    let url = API_HOST+ "/mobile/getImgCode?id=1";
    this.reg_code_img_url = url+"&type="+this.registerForm.__token__;
    this.login_code_img_url = url+"&type="+this.loginForm.__token__;
    this.getRegisterSelectData();
    this.getMemberInfo();
  },
  created() {
  },
  methods: {
    getMemberInfo() {
      MemberInfoDetail()
          .then(res => {
            if(res.data.code == 1) {
              this.registerForm = res.data.data;
            }else{
              this.$toast.fail('数据错误')
            }
          })
          .catch(reason => {
            this.$toast.fail('请求错误')
          })
    },
    onClickLeft() {
      this.$router.push('/user')
    },
    checkPassword(event) {
      if (this.registerForm.check_password.length <= 0) {
        this.check_password_msg = '再次输入密码确认';
        return false;
      }
      if (this.registerForm.password != this.registerForm.check_password) {
        this.check_password_msg = '两次密码输入不一致';
      } else {
        this.check_password_msg = '';
      }
    },
    onCLickCodeImage(e) {
      console.log(e)
      if(e === 1){
        console.log(1)
        this.registerForm.code_type+=1;
        this.reg_code_img_url = this.reg_code_img_url+'&v='+Math.random();
      }else{
        console.log(2)
        this.loginForm.code_type+=1;
        this.login_code_img_url = this.login_code_img_url+'&v='+Math.random();
      }
    },
    confirmDate(e) {
      console.log(e)
      let year = e.getFullYear();
      let month = e.getMonth() + 1;
      this.registerForm.date = year + '-' + month
      this.show_select_date = !this.show_select_date;
    },
    cancelDate(e) {
      this.show_select_date = !this.show_select_date;
    },
    confirmPicker(value, index){
      console.log(value)
      if (this.picker_type === 1) {
        this.registerForm.sex_text = value.text
        this.registerForm.sex_type = value.value;
      }else if (this.picker_type === 2) {
        this.registerForm.qualification_text = value.text
        this.registerForm.qualification_type = value.value;
      }else if(this.picker_type === 3){
        this.registerForm.province_name = value.text
        this.registerForm.province_id = value.value;
        if(value.value != this.branch_province_id){
          this.province_area = false;
        }
      }else if(this.picker_type === 4){
        this.registerForm.education_text = value.text
        this.registerForm.education_type = value.value;
      }else if(this.picker_type === 5){
        this.registerForm.major_text = value.text
        this.registerForm.major_type = value.value;
      }else if(this.picker_type === 6){
        this.registerForm.career_text = value.text
        this.registerForm.career_type = value.value;
      } else if(this.picker_type === 7){
        if(value.value != 1){
          this.show_no2 = false;
        }
        this.registerForm.register_text = value.text
        this.registerForm.register_type = value.value;
        this.qualification_list = value.qualification;
      }else if(this.picker_type === 8){
        this.registerForm.area_name = value.text
        this.registerForm.area_id = value.value;
      }else if(this.picker_type === 9){
        this.loginForm.login_text = value.text
        this.loginForm.login_type = value.value;
      }
      this.show_select_picker = !this.show_select_picker;
    },
    areaConfirm(e) {
      console.log(e)
      let length = e.length;
      let address = '';
      let area_id = 0;
      for (let i = 0; i < length; i++) {
        if (e[i].code === "") {
          this.$toast.fail('请选择地区');
          return false;
        }
        address += e[i].name;
        if (length - 1 === i) {
          area_id = e[i].code;
        }
      }
      if(this.picker_type === 7){
        this.registerForm.province_name = address;
        this.registerForm.province_id = area_id
      }else{
        this.registerForm.area_name = address;
        this.registerForm.area_id = area_id
      }

      this.show_select_picker = !this.show_select_picker;
      this.show_select_area = !this.show_select_area;
    },
    cancelPicker(e) {
      this.show_select_picker = !this.show_select_picker;
    },
    showDate(e){
      console.log('点击输入框');
      this.picker_type = e;
      if(e === 0){
        console.log(2)
        this.show_select_date = !this.show_select_date;
      }else{
        if(e === 1){
          this.picker_title = '请选择性别';
          this.picker_columns = [{text:'男',value:1}, {text:'女',value: 2}];
        }else if (e === 2){
          this.picker_title = '请选择资格类别';
          this.picker_columns = this.qualification_list;
        }else if (e === 3){
          this.picker_columns = this.province_list;
          this.picker_title = '请选择地区';
          // this.show_select_area=true;
        } else if (e === 4){
          this.picker_title = '请选择学历';
          this.picker_columns = this.education_list;
        }else if (e === 5){
          this.picker_title = '请选择专业';
          this.picker_columns = this.major_list;
        }else if (e === 6){
          this.picker_title = '请选择职称';
          this.picker_columns = this.technical_title;
        }else if (e === 7){
          this.picker_title = '请选择报名类别';
          this.picker_columns = this.cate_list;
        }else if (e === 8){
          this.picker_title = '请选择城市';
          this.picker_columns = this.city_list;
        }else if (e === 9){
          this.picker_title = '请选择登录类别';
          this.picker_columns = this.cate_list;
        }
        this.show_select_picker = !this.show_select_picker;
      }
    },
    onSubmitRegister(e) {
      if (this.registerForm.check_password != this.registerForm.password) {
        this.$toast.fail('两次密码输入不一致')
        return false;
      }
      // if(this.registerForm.original_password == this.registerForm.password){
      //   this.$toast.fail('新密码跟原密码一致')
      //   return false;
      // }
      editMemberInfo(this.registerForm)
          .then(res => {
            if (res.data.code === 1) {
              this.$toast.success(res.data.msg)
              this.getMemberInfo();
            } else {
              this.$toast.fail(res.data.msg)
              if (res.data.data.refresh === 1) {
                this.onCLickCodeImage(1);
              }
            }
          })
          .catch(err => {
            console.log(err.toString())
            this.$toast.fail('请求错误')
          })
    }
    , onChangeAgreement(e) {
      console.log(e)
    },
    getRegisterSelectData() {
      getRegisterSelectData()
          .then(res => {
            console.log(res)
            if(res.data.code === 1){
              let data = res.data.data;
              this.branch_province_id = data.branch_province_id;
              this.cate_list = data.cate_list;
              this.city_list = data.city_list;
              this.province_list = data.provinceData;
              this.technical_title = data.technical_title_list;
              this.education_list = data.education
              this.major_list = data.major_list;
              this.registerForm.province_id = data.branch_province_id
              this.registerForm.province_name = data.branch_province
            }
          }).catch(err => {

      })
    }
  },
  watch: {},
}
</script>
<style scoped lang="less">
@import '~@/assets/styles/common.less';

.register-form .van-field .van-field__label {
  width: 15rem !important;
}

.submit-but, .register-but {
  padding: 0 4rem !important;
}

.submit-but {
  margin-right: 5rem !important;
}


.container {
  height: 100vh;
  width: 100%;
  background-color: #fff;
  position: relative;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 0;
  height: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.register-box {
  text-align: center;
  padding: 6rem 1rem 6rem;
}

.register-title {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.h1 {
  font-size: 2.4rem !important;
  padding: 2rem 0 2rem;
  background: #4ba5ff;
  color: #fff;
}

.show-date {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.van-checkbox {
  width: 3rem;
}

.agreement-box {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.agreement-register-pop .popper-title {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 2rem 0;
  background: #07c160;
  color: #fff;
}

.agreement-register-pop .popper-content {
  text-align: left;
  padding: 7rem 1rem 2rem;
  font-size: 2rem;
  line-height: 3rem;
}

.agreement-register-pop .popper-content .content-title {
  font-size: 2.4rem;
  font-weight: 700;
}

.agreement-register-pop .popper-content p {
  padding: 0.2rem 0;
}

.logo {
  width: 15rem;
  position: absolute;
  right: 0;
  top: 0.5rem;
}

.area-input{
  border: none;
  width: 50% ;
}

.province-input{
  border: none;
  width: 100%
}
</style>